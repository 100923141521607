import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Navbar, TabPanel } from '../../components';
import BloodPressureReport from './blood-pressure-report';
import { actions, QueryParams, User } from './store/slice';
import TemperatureReport from './temperature-report';
import WeightReport from './weight-report';
import * as qs from 'qs';
import { selectReportDate, selectUsername } from './store/selector';
import { formatDate } from '../../utils/datetime';
import { subDays } from 'date-fns';

export enum DEVICE_TYPE {
  KEP = 'KEP',
  A0001 = 'A0001',
  B0001 = 'B0001',
  B0002 = 'B0002',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 10px',
  },
}));

function descrypt(identifier: string): User {
  const decryptIdentifier = (identifier: string) => {
    const content = Buffer.from(identifier, 'base64').toString('utf-8');
    if (content) {
      const contents = content.split(':');
      return {
        userId: contents[0],
        username: contents[1],
        serialNumber: contents[2],
      };
    }

    return null;
  };

  const userInfo = decryptIdentifier(identifier || '');
  return {
    userId: userInfo ? userInfo.userId : '',
    username: userInfo ? userInfo.username : '', // purpose: used for display username on UI
    date: 0,
    identifier: identifier || '', // purpose: to identify who is this user
    deviceSerialNumber: userInfo?.serialNumber || '',
  };
}

export const HealthReport = () => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(1);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const user = useMemo(() => {
    const identifier = searchParams.get('identifier');
    const date = searchParams.get('date') || '';

    if (!identifier) return undefined;

    const user: User = {
      ...descrypt(identifier),
      date: Number(date),
    };

    return user;
  }, [searchParams]);

  useEffect(() => {
    if (user) {
      dispatch(actions.loadHealthReport(user));
    }
  }, [dispatch, user]);

  return (
    <Paper className={classes.root} square elevation={0}>
      <Typography className="report_title">
        {user?.username}の健康報告書
      </Typography>
      {user?.date ? (
        <Typography className="report_datetime">
          {formatDate(subDays(user.date, 7).getTime(), 'yoMMMdo')}～
          {formatDate(user.date, 'yoMMMdo')}
        </Typography>
      ) : null}

      <Navbar
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        currentUser={user}
      />
      <TabPanel currentIndex={currentIndex} tabIndex={0}>
        <TemperatureReport reportDate={user?.date} />
      </TabPanel>
      <TabPanel currentIndex={currentIndex} tabIndex={1}>
        <BloodPressureReport reportDate={user?.date} currentUser={user} />
      </TabPanel>
    </Paper>
  );
};
